import React from "react";
import { FaSquareXTwitter, FaSquareYoutube } from "react-icons/fa6"; // Ensure you're using the correct icons
import { FaYoutube } from "react-icons/fa"; // Ensure you import FaYoutube if you need it

const XLink = () => {
  const handleXClick = () => {
    const appUrl = "twitter://user?screen_name=nordbotofficial"; // Corrected X app URL scheme
    const fallbackUrl = "https://x.com/nordbotofficial"; // X website URL

    // Try to open the X app
    window.location.href = appUrl;

    // Fallback to X website if app isn't opened
    setTimeout(() => {
      if (!document.hidden) {
        window.location.href = fallbackUrl;
      }
    }, 2000); // Adjust the delay as needed
  };

  return (
    <FaSquareXTwitter
      cursor="pointer"
      onClick={handleXClick}
      title="Open X App"
    />
  );
};

const YoutubeLink = () => {
  const handleYoutubeClick = () => {
    const appUrl = "youtube://www.youtube.com/channel/UCm5ZnITh1JKjc-O-100Pm0w"; // Correct YouTube app URL scheme
    const fallbackUrl =
      "https://www.youtube.com/channel/UCm5ZnITh1JKjc-O-100Pm0w"; // YouTube channel URL

    // Try to open the YouTube app
    window.location.href = appUrl;

    // Fallback to YouTube website if app isn't opened
    setTimeout(() => {
      if (!document.hidden) {
        window.location.href = fallbackUrl;
      }
    }, 3000); // Adjust the delay as needed
  };

  return (
    <FaSquareYoutube
      cursor="pointer"
      onClick={handleYoutubeClick}
      title="Open YouTube App"
    />
  );
};

export { XLink, YoutubeLink };
