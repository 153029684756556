import { FC } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";

export const RefundsPage: FC = () => {
  const navigate = useNavigate();
  return (
    <div className="legal-container">
      <nav className="sidebar">
        <h2>Navigation</h2>
        <ul>
          <li>
            <h5 onClick={() => navigate("/cookies")}>Cookies Policy</h5>
          </li>
          <li>
            <h5 onClick={() => navigate("/dsar")}>DSAR</h5>
          </li>
          <li>
            <h5 onClick={() => navigate("/privacy")}>Privacy Policy</h5>
          </li>
          <li>
            <h5 onClick={() => navigate("/refunds")}>Refund Policy</h5>
          </li>
          <li>
            <h5 onClick={() => navigate("/terms")}>Terms of Service</h5>
          </li>
        </ul>
      </nav>
      <main className="content">
        <div className="legal-box">
          <div className="policy-header">
            <h1>RETURN POLICY</h1>
            <p>Last updated 9/2/2024</p>
          </div>
          <div className="policy-section">
            <h2>REFUNDS</h2>
            <p>All sales are final and no refund will be issued.</p>
          </div>
          <div className="policy-section">
            <h2>QUESTIONS</h2>
            <p>
              If you have any questions concerning our return policy, please
              contact us at:
            </p>
            <a href="mailto:cs@nordbot.app?subject=Concerning%20the%20Refund%20Policy">
              cs@nordbot.app
            </a>
          </div>
        </div>
      </main>
    </div>
  );
};
