import { FC } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";

export const DsarPage: FC = () => {
  const navigate = useNavigate();
  return (
    <div className="legal-container">
      <nav className="sidebar">
        <h2>Navigation</h2>
        <ul>
          <li>
            <h5 onClick={() => navigate("/cookies")}>Cookies Policy</h5>
          </li>
          <li>
            <h5 onClick={() => navigate("/dsar")}>DSAR</h5>
          </li>
          <li>
            <h5 onClick={() => navigate("/privacy")}>Privacy Policy</h5>
          </li>
          <li>
            <h5 onClick={() => navigate("/refunds")}>Refund Policy</h5>
          </li>
          <li>
            <h5 onClick={() => navigate("/terms")}>Terms of Service</h5>
          </li>
        </ul>
      </nav>
      <main className="content">
        <div className="legal-box">
          <iframe
            title="DSAR Form"
            src="https://app.termly.io/notify/d267faed-48a5-497a-98d0-e64b099611d7"
          ></iframe>
        </div>
      </main>
    </div>
  );
};
