import React, { FC, ReactNode } from "react";
import { MainSection } from "./main";
import { MainHeader } from "./header";
import { MainFooter } from "./footer";
import "./index.css";

interface MainComponentProps {
  children: ReactNode;
}

export const MainComponents: FC<MainComponentProps> = ({ children }) => {
  return (
    <>
      <MainHeader />
      <MainSection>{children}</MainSection>
      <MainFooter />
    </>
  );
};
