import { FC } from "react";
import { FaCrown } from "react-icons/fa";
import "./index.css";
import { Urls } from "../../lib/contants";
export const MainHeader: FC = () => {
  async function handleLink(location: string) {
    window.location.href = location;
  }
  return (
    <header>
      <img onClick={() => handleLink('/')} alt="" src="/logo192.png" />
      <div className="button_promotional">
        <FaCrown />
        <p>Upgrade To Premium</p>
      </div>
      <div className="button_secondary" onClick={() => handleLink(Urls.SUPPORTSERVER)}>
        Support Server
      </div>
      <div className="button_login">Login</div>
    </header>
  );
};
