import { FC } from "react";
import { Socials, Urls } from "../../lib/contants";
import { FaSquareGithub } from "react-icons/fa6";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { FaCookie } from "react-icons/fa6";
import { XLink, YoutubeLink } from "../other/handleSocials";
export const MainFooter: FC = () => {
  const navigate = useNavigate();
  async function handleLink(location: string) {
    window.location.href = location;
  }
  return (
    <footer>
      <div className="footer-content">
        {/* Vertically aligned elements */}
        <div className="footer-left">
          <div className="footer-logo">
            <img alt="NORD logo" src="./logo192.png" />
            <p>NORD</p>
          </div>
          <div className="footer-tagline">The only bot you'll ever need.</div>
          <div className="footer-social">
            <XLink />
            <FaSquareGithub
              cursor={"pointer"}
              onClick={() => handleLink(Socials.GITHUB)}
            />
            <YoutubeLink />
          </div>
          <div className="footer-copyright">© 2021 - 2024 NordBot, Inc.</div>
        </div>
        {/* Grid of 4 sections */}
        <div className="footer-right">
          <div className="footer-section">
            <h4>Features</h4>
            <ul>
              <li>
                <h5>Social</h5>
              </li>
              <li>
                <h5>Moderation</h5>
              </li>
              <li>
                <h5>Fun</h5>
              </li>

              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}

              <h6 className="termly-display-preferences">
                <FaCookie
                  color="#a7a7a7"
                  size={12}
                  style={{ marginRight: "5px" }}
                />
                Consent Preferences
              </h6>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Support</h4>
            <ul>
              <li>
                <a href={Urls.SUPPORTSERVER}>Support Server</a>
              </li>
              <li>
                <h5>Premium</h5>
              </li>
              <li>
                <h5>Docs</h5>
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Company</h4>
            <ul>
              <li>
                <h5>About Us</h5>
              </li>
              <li>
                <h5>Careers</h5>
              </li>
              <li>
                <a href={Urls.TOPGG}>Vote For Us</a>
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Legal</h4>
            <ul>
              <li>
                <h5 onClick={() => navigate("/cookies")}>Cookies</h5>
              </li>
              <li>
                <h5 onClick={() => navigate("/privacy")}>Privacy</h5>
              </li>
              <li>
                <h5 onClick={() => navigate("/refunds")}>Refunds</h5>
              </li>
              <h5 onClick={() => navigate("/terms")}>Terms of Service</h5>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};
