import { FC } from "react";
import "./loginPage.css";

export const LoginPage: FC = () => {
  return (
    <div className="hero">
      <div className="hero-greeting">
        <h3 className="welcome">Welcome to</h3>
        <h3 className="nord">NORD</h3>
      </div>
      <p className="hero-tagline">Your journey to excellence starts here.</p>
      <div className="hero-buttons">
        <button className="hero-button">
          <span className="icon">🚀</span> Get Started
        </button>
        <button className="hero-button-secondary">Dashboard</button>
      </div>
      <div className="hero-logo">
        <img alt="" src="/logo512.png" height={"300px"} />
      </div>
      <div className="bot-stats">
        <div className="bot-stat">
          <h4>4,000+</h4>
          <p>Communities</p>
        </div>
        <div className="bot-stat">
          <h4>2.3+ Million</h4>
          <p>Commands processed per month</p>
        </div>
        <div className="bot-stat">
          <h4>100,000+</h4>
          <p>New Users per month</p>
        </div>
      </div>
    </div>
  );
};
