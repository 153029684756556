import { FC, useEffect, useState } from "react";
import "./index.css";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";

export const CookiesPage: FC = () => {
  const [cookiesPolicyHTML, setCookiesPolicyHTML] = useState<string | null>(
    null
  );
  const navigate = useNavigate();
  useEffect(() => {
    fetch("/legal/cookies.html")
      .then((response) => response.text())
      .then((data) => setCookiesPolicyHTML(data))
      .catch((error) => console.error("Error loading cookies policy:", error));
  }, []);
  return (
    <div className="legal-container">
      <nav className="sidebar">
        <h2>Navigation</h2>
        <ul>
          <li>
            <h5 onClick={() => navigate("/cookies")}>Cookies Policy</h5>
          </li>
          <li>
            <h5 onClick={() => navigate("/dsar")}>DSAR</h5>
          </li>
          <li>
            <h5 onClick={() => navigate("/privacy")}>Privacy Policy</h5>
          </li>
          <li>
            <h5 onClick={() => navigate("/refunds")}>Refund Policy</h5>
          </li>
          <li>
            <h5 onClick={() => navigate("/terms")}>Terms of Service</h5>
          </li>
        </ul>
      </nav>
      <main className="content">
        <div className="legal-box">
          {cookiesPolicyHTML ? parse(cookiesPolicyHTML) : "Loading..."}
        </div>
      </main>
    </div>
  );
};
