import { Route, Routes } from "react-router-dom";
import { MainComponents } from "./components";
import { LoginPage } from "./pages/loginPage";
import { PrivacyPage } from "./pages/legal/privacyPage";
import { RefundsPage } from "./pages/legal/refundsPage";
import { CookiesPage } from "./pages/legal/cookiesPage";
import { TermsPage } from "./pages/legal/termsPage";
import { DsarPage } from "./pages/legal/dsarPage";

function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <MainComponents>
              <LoginPage />
            </MainComponents>
          }
        />
        <Route
          path="/cookies"
          element={
            <MainComponents>
              <CookiesPage />
            </MainComponents>
          }
        />
        <Route
          path="/dsar"
          element={
            <MainComponents>
              <DsarPage />
            </MainComponents>
          }
        />
        <Route
          path="/privacy"
          element={
            <MainComponents>
              <PrivacyPage />
            </MainComponents>
          }
        />
        <Route
          path="/refunds"
          element={
            <MainComponents>
              <RefundsPage />
            </MainComponents>
          }
        />
        <Route
          path="/terms"
          element={
            <MainComponents>
              <TermsPage />
            </MainComponents>
          }
        />
      </Routes>
    </>
  );
}

export default App;





